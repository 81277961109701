<template>
  <ion-page>
    <app-content v-if="!isAppLoading">
 
      <ion-grid>
        <ion-row>
          <ion-col>
            <div class="logo-box">
              <img src="@/assets/images/logo1.png" alt="" />
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <SignIn v-if="screen === 'login'" @complete="showScreen" />
      <Register v-if="screen == 'register'" @complete="showScreen" />
      <Verify v-if="screen == 'verify'" @complete="showScreen('program')" />
    </app-content>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router";
import {computed, ref} from "vue"
import {
  IonPage,
  IonCol,
  IonRow,
  IonGrid,
 
} from "@ionic/vue";
import { useStore } from "vuex";
import AppContent from "../components/AppContent.vue";
import Register from "@/components/Login/Register.vue";
import Verify from "@/components/Login/Verify.vue";
import SignIn from "@/components/Login/SignIn.vue";

export default {
  name: "Login",
  components: {
    IonGrid,
    IonPage,
    AppContent,
    IonCol,
    IonRow,

    Register,
    Verify,
    SignIn,
  },
 

  setup() {
    const router = useRouter();
    const store = useStore();
    const screen = ref("register");
    const isAppLoading = computed( ()=> {return store.getters['isLoading'] }) 
    const currentUser = computed( ()=> {return store.getters['getCurrentUser'] });

    //Actions
    // const getBranding = store.dispatch("getBranding")
    // const registerUserWithCode = store.dispatch("axCreateSubscription")
    // const firsttimeSubscriberRequest = store.dispatch("firsttimeSubscriberRequest")
    // const axLoginByPass = store.dispatch("axLoginByPass")

    const byPassLogin = async () =>{
      await store.dispatch('axLoginByPass');
      router.push('/tabs/home')
    }
    const showScreen = (screenName) =>{
      screen.value = screenName;
    };

    return {
      router, showScreen,screen, byPassLogin,
      isAppLoading, currentUser,
      //getBranding, registerUserWithCode, firsttimeSubscriberRequest, axLoginByPass,
      appName: "Results Unleashed",
      contentStyle: store.state.branding,
    };
  },
};
</script>

<style scoped>
.note-input {
  margin-top: 5px;
  --background: none;
  background: none;
}
.note-input p {
  text-align: left;
  margin: 4px 10px;
  font-size: 15px;
  color: var(--ion-color-secondary-tint);
  color: #414042;
  width: 100%;
  margin-left: 0;
}
.app-form ion-input {
  color: #000;
  --background: #23ff11;
  --border-bottom: none;
  border: none;
  --border: none;
  border-radius: 5px;
  --padding-start: 0.5rem;
  --inner-padding-start: 0.5rem;
}
.logo-box {
  width: 100%;
  margin-top: 25px;
  color: green;
}
.logo-box img {
  min-width: 100px;
  width: 250px;
  margin: auto;
  display: block;
}
.sub-actions {
  margin-left: 19px;
  margin-right: 2px;
}

.button-custom {
  color: #414042 !important;
}
</style>
