<template>
  <ion-grid>
    <ion-row name="register">
      <ion-col>
        <div class="app-form">
          <ion-list lines="none">
            <ion-item class="inputItem">
              <ion-label position="stacked">First Name</ion-label>
              <ion-input v-model="authForm.firstName"></ion-input>
            </ion-item>
            <ion-item class="inputItem">
              <ion-label position="stacked">Last Name</ion-label>
              <ion-input v-model="authForm.lastName"></ion-input>
            </ion-item>
            <ion-item class="inputItem">
              <ion-label position="stacked">Email</ion-label>
              <ion-input v-model="authForm.email"></ion-input>
            </ion-item>
            <ion-item class="note-input" v-if="appSettings.showCode">
              <p>Have a referral code ? Use it </p>
            </ion-item>
            <ion-item class="inputItem"  v-if="appSettings.showCode">
              <ion-label position="stacked">Referral Code</ion-label>
              <ion-input
                v-model="authForm.code"
                placeholder="XXXXX"
              ></ion-input>
            </ion-item>
          </ion-list>
          <ion-row class="sub-actions">
            <ion-col >
              <ion-button
                @click="createUser(authForm)"
                color="primary"
                expand="full"
                shape="round"
              >
                Start
              </ion-button>
            </ion-col>
          
          </ion-row>
          <!-- <ion-row class="sub-actions">
            <ion-col>
              <ion-button
                shape="round"
                @click="gotoHome()"
                color="secondary"
                expand="full"
                fill="outline"
              >
                Sign In
              </ion-button>
            </ion-col>
          </ion-row> -->
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import {
  IonButton,
  IonLabel,
  IonInput,
  IonList,
  IonItem,
  IonCol,
  IonRow,
  IonGrid,
} from "@ionic/vue";
import { useStore } from "vuex";
import { Storage } from '@capacitor/storage';
export default {
  name: "Login",
  components: {
    IonGrid,
    IonLabel,
    IonInput,
    IonButton,
    IonList,
    IonItem,
    IonCol,
    IonRow,
  },
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const authForm = ref({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      code: "",
    });
    const currentUser = computed(() => store.getters.getCurrentUser);
    store.dispatch("axAppSettings")
    const appSettings = computed(() => store.getters.getAppSettings);
    const getBranding = () => {
      return store.dispatch("getBranding");
    };
    const axAppSettings = () => {
      return store.dispatch("axAppSettings");
    };

    const createUser = async (authForm) => {
      await Storage.set({key: 'userEmail', value:authForm.email });
      await store
        .dispatch("axCreateUser", authForm)
        .then((res) => console.log(res, "res"))
        .catch((error) => console(error, "error"));
      return router.push("/tabs/home");
    };

    const saveUser = () => {
      context.emit("complete", "verify");
    };
    const gotoHome = () => {
      context.emit("complete", "login");
    };

    const formIsDisabled = () => {
      // check for empty values
      const formValues = [
        authForm.value.firstName,
        authForm.value.lastName,
        authForm.value.email,
      ];

      if (formValues.findIndex((value) => value.length === 0) > -1) {
        return true;
      }
      return false;
    };

    return {
      router,
      saveUser,
      currentUser,
      authForm,
      getBranding,
      axAppSettings,
      appName: "Results Unleashed",
      createUser,
      contentStyle: store.state.branding,
      gotoHome,
      formIsDisabled,
      appSettings
    };
  },
};
</script>

<style scoped>
.app-form {
  margin-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.app-form ion-list {
  background: none !important;
}

.app-form ion-list .inputItem {
  border-radius: 6px;
  background: #fff !important;
  margin: 0 0 12px;
}

.app-form ion-item.note-input {
  --background: none;
  --padding-start: 0;
}

.app-form .sub-actions ion-col {
  padding: 0;
}

.app-form ion-button {
  --border-radius: 6px !important;
}
</style>
