<template>
  <ion-content>
    <div class="register-content">
      <slot></slot>
      <div class="bottom-wedge"></div>
    </div>
  </ion-content>
</template>

<script>
import { IonContent } from "@ionic/vue";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "AppContent",
  components: {
    IonContent,
  },
});
</script>

<style>
.register-content {
  min-height: 100vh;
  background: url("../assets/images/background02.png");
  background-size: cover;
}

.bottom-wedge {
  height: 50px;
}
</style>
