<template>  
    <ion-grid>
        <ion-row v-if="!sentVerification">
            <ion-col>
            <p style="color:red">We need to verify your device. </p>
            <p> us know if you would like to receive a code via email or text.</p>
            <div class="ion-text-center" style="margin-botton: 26px">
            <ion-button class="sbtn" expand='full' @click="sendVerification('email')"> Send Via Email</ion-button>
            </div>  
            </ion-col>
        </ion-row>
        <ion-item v-if="!sentVerification">
          <ion-label style="font-size: 14px">Phone Number:</ion-label>
          <ion-input></ion-input>
           <div class="ion-text-center" style="margin-top: 26px">
               <ion-button @click="sendVerification('txt')"> Send Via Text</ion-button>
           </div>
        </ion-item>
        <ion-row v-if="sentVerification">
            <ion-col>
               
                <p style="margin: 60px 0px 15px">Enter verification code below:</p>
            <ion-input class = "input1"></ion-input>
            </ion-col>
        </ion-row>
    </ion-grid>      
</template>
<script >
import { useStore} from 'vuex';
import { computed, ref } from 'vue';
import { IonGrid, IonItem, IonLabel, IonInput, toastController } from '@ionic/vue';


export default {
  name: 'Verify',
  components: {  IonGrid, IonItem, IonLabel, IonInput },
  
  setup() {
      const store = useStore();
      const contentStyle = store.state.content
      const menuStyle = store.state.menu
      const sentVerification = ref(false)
      const verificationCode = computed( ()=> store.getters.getVerificationCode)
      const axSendVerificationCode = (payload) => store.dispatch("axSendVerificationCode", payload)
      const toastr = async (config) =>{
          const t = await toastController.create(config)
          t.present()
      }
      const sendVerification = async(via)=>{
          await axSendVerificationCode({via})
          sentVerification.value = true;
          const payload = {message:`SENT!! via ${via}`, duration: 3000}
          toastr(payload)
      }
 
      
      return {
        contentStyle,
        menuStyle,
        verificationCode,
        sendVerification,
        sentVerification,
        toastr
       
      }
  }
}
</script>
<style scoped>
.sbtn{
    --background:navy !important;
}
 .input1 { 
   width: 176px;
   height: 43px;
   border: black solid 1px;
   margin:  18px auto;
 }
</style>