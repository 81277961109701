<template>
  <ion-grid>
    <ion-row name="register">
      <ion-col>
        <div class="app-form">
          <ion-list>
            <ion-item class="inputItem">
              <ion-label position="stacked">Email</ion-label>
              <ion-input v-model="authEmail"></ion-input>
            </ion-item>
          </ion-list>
          <ion-row class="sub-actions">
            <ion-col>
              <ion-button
                @click="loginUser(authEmail)"
                color="primary"
                expand="full"
                shape="round"
                :disabled="authEmail.length == 0 || loginUserSccess"
              >
                Sign In
                <ion-spinner name="dots" v-if="loginUserSccess">
                  Signing...
                </ion-spinner>
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button
                shape="round"
                @click="gotoRegister()"
                color="secondary"
                expand="full"
                fill="outline"
              >
                Register
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import {
  IonButton,
  IonLabel,
  IonInput,
  IonList,
  IonItem,
  IonCol,
  IonRow,
  IonGrid,
  IonSpinner,
  toastController,
} from "@ionic/vue";
import { useStore } from "vuex";
import { ref, defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Login",
  components: {
    IonGrid,
    IonLabel,
    IonInput,
    IonButton,
    IonList,
    IonItem,
    IonCol,
    IonRow,
    IonSpinner,
  },

  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const authEmail = ref("");
    const loginUserSccess = ref(false);
    const saveUser = () => {
      context.emit("complete");
    };
    const gotoRegister = () => {
      context.emit("complete", "register");
    };
    const loginUser = async (email) => {
      loginUserSccess.value = true;
      const res = await store.dispatch("axLoginUser", { email });

      //
      if (res) {
        try {
          await Storage.set({key: 'userEmail', value:email,  });
          setTimeout(() => {
            loginUserSccess.value = false;
            router.push("/tabs/home");
          }, 700);
        } catch (e) {
          console.log(e, "Couldnt go to route");
        }
      } else {
        //Show Alert that it was not found.
        loginUserSccess.value = false;
        const sn = await toastController.create({
          message: "Email not found. Try again or click register.",
          position: "top",
          color: "primary",
          duration: 2500,
          animated: true,
        });
        sn.present();
      }
    };

    return {
      loginUserSccess,
      saveUser,
      authEmail,
      gotoRegister,
      loginUser,
      appName: "TK2P",
      contentStyle: store.state.branding,
    };
  },
});
</script>

<style scoped>
.app-form {
  margin-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.app-form ion-list {
  background: none !important;
}

.app-form ion-list .inputItem {
  border-radius: 6px;
  background: #fff !important;
  margin: 0 0 12px;
}

.app-form ion-item.note-input {
  --background: none;
  --padding-start: 0;
}

.app-form .sub-actions ion-col {
  padding: 0;
}

.app-form ion-button {
  --border-radius: 6px !important;
}
</style>
